export const environment = {
    production: true,
    apibase: 'https://fp.volant-guard.makalu.live/api',
    apiVersion: 'v1',
    wsbase: 'https://fp.volant-guard.makalu.live/api',
    authorityUrl: 'https://auth.volant-guard.makalu.live/auth',
    realm: 'volant-guard',
    clientId: 'file-processing-ui',
    uiVersion: '1.0.10'
};
